@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #121212 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
*,
::before,
::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
