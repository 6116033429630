.vendorDashMain {
  min-height: 100vh;
  width: 100%;
  padding: 20px;

  .vendorDashContent {
    .vendorContentTitle {
      font-weight: 600;
      font-size: 24px;
      margin: 20px 0;
      color: rgba(255, 255, 255, 0.87);
    }
  }
}
.vendorDashMenuIcon {
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
}
