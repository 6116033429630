.jobCardView {
  margin: 18px;
  .show-report-con {
    .report-sec {
      width: 100%;
      overflow-x: auto;
      scrollbar-width: thin;
      margin-bottom: 2rem;
      padding-bottom: 1rem;
      table {
        td {
          min-width: 150px;
          padding-left: 5px;
        }
      }
      &::-webkit-scrollbar {
        height: 10px;
        background: #1c1c1c;
      }
      &::-webkit-scrollbar-thumb {
        background: #aaa;
      }
    }
  }
  .jobCardView-title {
    font-weight: 600;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.87);
    padding: 4px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.38);
  }
  .jobCardView-header {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.87);
    }
    p {
      text-align: right;
      font-weight: 600;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.87);
      span {
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.38);
      }
    }
  }
  .jobCardView-jobs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .jobCardView-job {
      min-width: 350px;
      min-height: 180px;
      padding: 20px;
      margin: 10px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 8px;
      // transform: matrix(1, 0, 0, 1, 0, 0);
      p {
        text-align: right;
      }
      h3 {
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.87);
      }
    }
  }
  .jobCardView-details {
    .jobCardView-detail {
      display: flex;
      align-items: center;
      p:nth-child(1) {
        flex: 1;
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
      }
      p:nth-child(2) {
        flex: 1.2;
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
      }
      .jobCardView-detailCall {
        flex: 0.2;
      }
    }
  }
  .jobCardView-invoices {
    .jobCardView-invoices-hd {
      font-weight: 600;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.38);
      margin: 0;
    }
    .jobCardView-invoices-id {
      font-weight: 600;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.87);
      margin: 0;
    }
    .jobCardView-invoice {
      display: flex;
      p {
        font-weight: 600;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
        margin: 8px 0;
        span {
          font-weight: 600;
          font-size: 11px;
          color: rgba(255, 255, 255, 0.38);
        }
      }
      p:nth-child(1) {
        flex: 0.1;
      }
      p:nth-child(2) {
        flex: 0.75;
      }
      p:nth-child(3) {
        flex: 0.15;
        text-align: right;
      }
    }
    .jobCardView-invoice-divider {
      height: 2px;
      background-color: rgba(255, 255, 255, 0.38);
      border-radius: 10px;
    }
  }
  .jobCardView-productsAdd {
    margin: 25px 0;
    .ant-input {
      flex: 4;
      min-height: 50px;
      background-color: transparent;
    }
    .jobCardView-productsAddIcon {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      border-radius: 8px;
      margin-left: 8px;
      border: 1px solid rgba(255, 255, 255, 0.08);
    }
  }
  .jobCardView-products {
    margin-top: 30px;
    .jobCardView-product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: inset 0px -1px 1px rgba(255, 255, 255, 0.12);
      padding: 12px 10px;
      h3,
      p {
        margin: 0;
      }
      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #ffffff;
      }
      p {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
      .jobCardView-productLeft {
        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
  .jobCardView-footer {
    padding: 25px 0 50px 0;
    p {
      margin: 10px;
    }
    .jobCardView-footerInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      p {
        font-weight: 600;
        font-size: 14px;
        margin: 20px 0;
        span {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      button {
        outline: none;
        border: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #ff0000;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 700px) {
    margin: 40px;
  }
  @media screen and (min-width: 1000px) {
    margin: 50px;
  }
}
