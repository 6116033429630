.serviceExpandedCard {
  min-height: 111px;
  width: 100%;
  max-width: 360px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  margin: 20px 0;

  .imgContainer {
    flex: 0.3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .serviceDetails {
      p,
      h2 {
        margin: 0;
        padding: 0;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
      }

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #ffffff;
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(255, 255, 255, 0.38);
      }
    }

    .stockDetails {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #ffffff;
    }
  }
}
