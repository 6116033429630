.autoLogs {
    display: flex;
    flex-direction: column;
    .auto_log_cover {
      box-shadow: inset 0px -1px 1px rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-between;
      height: 72px;
      align-items: center;
      .auto_date {
        display: flex;
        flex-direction: column;
        h3 {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
  
          letter-spacing: 0.15px;
  
          color: #ffffff;
        }
        h6 {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */
  
          letter-spacing: 0.4px;
  
          /* Medium Emphasis */
  
          color: rgba(255, 255, 255, 0.6);
        }
        span {
          display: flex;
        }
      }
    }
  }
  