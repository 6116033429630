.vendorStackCard {
  background: linear-gradient(
      0deg,
      rgba(240, 92, 45, 0.08),
      rgba(240, 92, 45, 0.08)
    ),
    rgba(255, 255, 255, 0.08);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  padding: 10px;
  //   width: 150px;
  //   height: 100px;

  .cardCol1 {
    flex: 1;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .cardCol2 {
    flex: 1;
    font-size: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardCol3 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .stackCardIcon {
      color: #ff6f00;
    }
  }
}
