.inventoryAddFormWrapper {
  .inventoryAddForm {
    display: flex;
    align-items: center;
    .ant-form-item {
      margin: 0;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-number {
      height: 50px;
    }
    .inventoryAddForm-pdt {
      flex: 2;
    }
    .inventoryAddForm-stock {
      flex: 1;
    }
    .inventoryAddForm-pdt,
    .inventoryAddForm-stock {
      margin: 10px 5px;
    }
  }
  svg {
    color: rgba(255, 255, 255, 0.6);
  }
  .inventoryAddForm-add {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.08);
    margin: auto;
    border-radius: 1000px;
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .inventoryAddForm-submit {
    width: fit-content;
    margin: auto;
  }
}

.inventoryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  .ant-form {
    width: 90%;
  }
  .ant-input,
  .ant-picker,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 50px;
  }
  .inventoryForm-submit {
    width: 50%;
    margin: auto;
  }
}
.inventoryForm-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  border-radius: 1000px;
  img {
    min-height: 80px;
  }
}
