.vendorExpandedContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  .avatarContainer {
    padding: 20px 0;
  }

  .contentTable {
    margin-top: 20px;
    width: 100%;
    .tableRow {
      width: 100%;
      display: flex;
      overflow: hidden;
      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-word;
      p {
        margin: 0;
        padding: 0;
        width: 50%;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.87);

        &:nth-child(2) {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
