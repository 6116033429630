.serviceContent {
  padding: 40px 20px;
  width: 100%;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #ffffff;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.6);
  }
}
